import React, {Component} from 'react';
import Info from "./Info";


class Popup extends Component {
    render() {
        return (
            <div style={popupOuter} onClick={() => {this.props.toggle()}}>
                    <div className="mt-5 ml-4 mr-4 rounded-lg popup-inner" style={popupInner} onClick={(event)=> {event.stopPropagation()}}>
                        <button className="btn mr-2" style={closeButton} onClick={() => {this.props.toggle()}}>&#10006;</button>
                        <div className="pt-4 p-2">
                        {(function(that) {
                            switch(that.props.popUpType) {
                                case 'addColumn':
                                    return <Info key="info"/>;
                                default:
                                    return <Info key="info"/>;
                            }
                        })(this)}
                        </div>
                    </div>
            </div>)
    }

}



const popupInner = {
    position: "absolute",
    background: "white",
    maxHeight: "90vh",
    overflow: "scroll",
};

const closeButton = {
    float: "right",
    marginTop: "0.8rem"
};

const popupOuter = {
    zIndex: "1000",
    position: "fixed",
    width: "100%",
    height: "100%",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    margin: "auto",
    backgroundColor: "rgba(56, 68, 77, 0.9)"
};

export default Popup;
