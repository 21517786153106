import React, {Component} from 'react';


class Error extends Component {


    render() {
        return (
            <div className="container text-center">
                <div className="mt-5">
                    <p>{this.props.message}</p>
                </div>
            </div>
        );
    }
};

export default Error;
