import React, {Component} from 'react';


class Result extends Component {

    copyToClipboard = (event) => {
        let str = event.target.value;
        const el = document.createElement('textarea');
        el.value = str;
        el.setAttribute('readonly', '');
        el.style.position = 'absolute';
        el.style.left = '-9999px';
        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);

        const copied = document.createElement('p');
        copied.innerText = "Copied to clipboad!";
        copied.classList.add("text-center");
        document.body.appendChild(copied);

        var fadeTarget = copied;
        var fadeEffect = setInterval(function () {
            if (!fadeTarget.style.opacity) {
                fadeTarget.style.opacity = 1;
            }
            if (fadeTarget.style.opacity > 0) {
                fadeTarget.style.opacity -= 0.1;
            } else {
                clearInterval(fadeEffect);
                document.body.removeChild(copied)
            }
        }, 240);
        event.target.select();
    };

    render() {
        return (
            <div className="container col-md-4">
                <div className="mt-5 form-group">
                    <label htmlFor="result"><i style={{color: "green"}} className="fa fa-check"
                                               aria-hidden="true"></i> Click to copy short link:</label>
                    <textarea name="result" className="form-control" style={{textAlign: "justify", cursor: "default"}}
                              rows="1"
                              onClick={(event) => {
                                  this.copyToClipboard(event);
                              }} id="shorturl" value={this.props.shorturl}
                              readOnly></textarea>
                </div>
            </div>
        );
    }
};


export default Result;
