import React, {Component} from 'react';


class Footer extends Component {


    render() {
        return (
            <React.Fragment>
                <div className="d-none d-md-block text-center" style={socialmedia}>
                    <p>
                        <button className="btn btn-sm btn-outline-dark" onClick={() => this.props.toggle()} style={about}>ABOUT</button>
                    </p>
                    <a className="mr-2" href="https://twitter.com/DemmerDennis"><img style={{width: "2.1rem"}}
                                                                                     alt="twitter logo"
                                                                                     src={require('./../img/twitter.png')}/></a>
                    <a className="mr-1" href="https://github.com/DDemmer1/prune-link-backend"><img
                        style={{width: "2rem"}} alt="github logo"
                        src={require('./../img/github.png')}/></a>
                    <a href="https://paypal.me/DemmerDennis"><img style={{width: "2.7rem"}} alt="github logo"
                                                                  src={require('./../img/donate.png')}/></a>

            </div>
            </React.Fragment>
        );
    }
};


const socialmedia = {
    position: "absolute",
    bottom: "0",
    width: "80vh",
    left: "50%",
    transform: "translate(-50%, -50%)"
};

const about = {
    letterSpacing: "1pt",
    fontWeight: "bold",
};

export default Footer;
