import React, {Component} from 'react';
import './App.css';
import './style.css';
import * as axios from "axios";
import Result from "./components/Result";
import Error from "./components/Error";
import Footer from "./components/Footer";
import Header from "./components/Header";
import Popup from "./components/popups/Popup";

const baseUrl = "https://qak.me";

class App extends Component {


    state = {
        message: "",
        originalUrl: "",
        alias: "",
        success: false,
        error: false,
        popUpType: "",
        showPopup: false
    };


    togglePopup = (popUpType) => {
        this.setState({
            showPopup: !this.state.showPopup,
            popUpType: popUpType
        });
    };

    handleSubmit = (event) => {
        event.preventDefault();

        var that = this;
        axios.post(baseUrl + "/shorten", {
            originalUrl: this.state.originalUrl,
            alias: this.state.alias
        })
            .then(function (response) {
                that.setState({message: response.data.message});

                if (response.data.success) {
                    that.setState({success: true});
                    that.setState({error: false});
                } else {
                    that.setState({success: false});
                    that.setState({error: true});
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    };


    handleAliasInput = (event) => {
        let alias = event.target.value;
        this.setState({alias: alias});
    };

    handleUrlInput = (event) => {
        let originalUrl = event.target.value;
        this.setState({originalUrl: originalUrl});
    };

    render() {
        return (
            <React.Fragment>
                <Header toggle={this.togglePopup.bind(this)}/>
                <div className="jumbotron bg-transparent mb-0 pb-0">
                    <h1 style={mainTitle} className="mb-2 text-center">
                        <div ><img style={{width: "2.7rem"}} alt="github logo" src={require('./img/qak.png')}/></div>
                        <span style={{fontWeight: "800"}}> qak.</span>me</h1>
                    <h5 className="text-center mb-5">Free Link shortener</h5>
                    <div className="container col-10 col-md-10 col-lg-6 col-xl-5">
                        <h5 className="mb-3 ">Create Custom Click-Worthy Links</h5>
                        <form className="mt-4" onSubmit={(event) => this.handleSubmit(event)}>
                            <div className="input-group">
                                <input type="text" id="ur" name="url" className="form-control"
                                       placeholder="Shorten your link"
                                       onChange={(event) => this.handleUrlInput(event)}
                                       required/>
                            </div>

                            <label className="mt-4" htmlFor="alias">Enter a custom name <b>(optional)</b> </label>
                            <div className="input-group mb-2">
                                <div className="input-group-prepend">
                                    <div className="input-group-text">qak.me</div>
                                </div>
                                <input type="text" id="alias" name="alias" className="form-control"
                                       placeholder="e.g. ClientPhotos"
                                       onChange={(event) => this.handleAliasInput(event)}/>
                            </div>


                            <button type="submit" className="mt-3 btn btn-info">Shorten!</button>
                        </form>
                    </div>
                    {this.state.success ? <Result shorturl={this.state.message}/> : null}
                    {this.state.error   ? <Error   message={this.state.message}/> : null}
                </div>
                <Footer toggle={this.togglePopup.bind(this)}/>
                {this.state.showPopup ? <Popup popUpType={this.state.popUpType} toggle={this.togglePopup.bind(this)}/> : null}
            </React.Fragment>
        );
    }
}





const mainTitle = {
    fontFamily: "helvetica",
    textTransform: "uppercase",
    letterSpacing: "0.1em",
    color: "#2f2f2d",
};


export default App;
