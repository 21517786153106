import React, {Component} from 'react';


class Header extends Component {


    render() {
        return (
            <div className="mb-4 d-md-none">
                <div className="m-3 " style={socialmedia}>
                    <a className="mr-2" href="https://twitter.com/DemmerDennis"><img style={{width: "1.8rem"}}
                                                                                     alt="twitter logo"
                                                                                     src={require('./../img/twitter.png')}/></a>
                    <a href="https://github.com/DDemmer1"><img style={{width: "1.7rem"}} alt="github logo"
                                                               src={require('./../img/github.png')}/></a>
                </div>
                <div style={{textAlign:"left"}} className="m-3" >
                    <img onClick={() => this.props.toggle()} style={{width: "1.7rem"}} alt="github logo" src={require('./../img/info.svg')}/>
                </div>
            </div>
        );
    }
};


const socialmedia = {
    position: "absolute",
    top: "0",
    right: "0"
};

export default Header;
